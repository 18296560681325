<template>
    <headers></headers>
    <div class="top-gap"></div>
    <div class="desc">
        <div>
            <p>{{ $t('idos.title') }}</p>
            <b>{{ $t('idos.total') }}:
                <span>300,000,000</span>
            </b>
        </div>
        <img class="chat-pc" :src="getUrl('-pc', 0)" alt="">
        <img class="chat-m" :src="getUrl('-m',0)" alt="">
    </div>
    <div class="desc-bg">
        <div v-for="(item,index) in 6" :key="index">
            <div>
                <div class="gap"></div>
                <b>{{ $t('idos.descTitle' + (index + 1)) }}</b>
            </div>
            <p>{{ $t('idos.desc' + (index + 1)) }}</p>
        </div>
    </div>
    <div class="content">
        <div class="module">
            <b>{{ $t('idos.type1') }}</b>
            <p>{{ $t('idos.type1Time') }}</p>
            <img class="light" src="../../static/images/light.png" alt="">
            <div>
                <img src="../../static/images/type1.png" alt="">
                <div class="right">
                    <div>
                        <span>{{ $t('idos.price') }}</span>
                        <span>0.1USDT/SolFi</span>
                    </div>
                    <div>
                        <span>{{ $t('idos.total') }}</span>
                        <span class="weight">1,000,000</span>
                    </div>
                    <div>
                        <span>{{ $t('idos.amount') }}</span>
                        <span>{{ $t('idos.unit',{amount: 200}) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="module">
            <b>{{ $t('idos.type2') }}</b>
            <p>{{ $t('idos.type2Time') }}</p>
            <img class="light" src="../../static/images/light.png" alt="">
            <div>
                <img src="../../static/images/type2.png" alt="">
                <div class="right">
                    <div>
                        <span>{{ $t('idos.price') }}</span>
                        <span>0.14USDT/SolFi</span>
                    </div>
                    <div>
                        <span>{{ $t('idos.total') }}</span>
                        <span class="weight">1,430,000</span>
                    </div>
                    <div>
                        <span>{{ $t('idos.amount') }}</span>
                        <span>{{ $t('idos.unit',{amount: 400}) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="advantage">
        <div v-for="(item,index) in 3" :key="index">
            <img :src="getUrl(index+1,1)" alt="">
            <div>
                <p>{{ $t('idos.advantageTitle' + (index+1)) }}</p>
                <span>{{ $t('idos.advantageDesc' + (index+1)) }}</span>
            </div>
        </div>
    </div>
    <div class="base">
        <b>{{ $t('idos.ido') }}</b>
        <div>
            <span>{{  Util.omitAddress(address,20) }}</span>
            <img @click="copys(address)" src="../../static/images/copy.png" alt="">
        </div>
    </div>
</template>

<script setup>
    import headers from '@/components/header/index.vue'
    import { watch, computed, ref, onMounted } from 'vue';
    import { webStore } from "@/store/web"
    import { walletStore } from "@/store/wallet"
    import {memberStore} from "@/store/member"

    const wbStore = webStore()
    const wStore = walletStore()
    const mStore = memberStore()
    
    import { ElMessage } from "element-plus";
    import Util from '@/utils/common-util'
    import i18n from "@/i18n";

    let address = ref('4TYF4heYScRTHM2374neZUMqeVRyRXXyqpWasndJM3aj')

    onMounted(() => {
        language.value = localStorage.getItem('locale')
    })

    const language = ref('en')
    const languages = computed(() => {
        return i18n.global.locale
    })

    watch(languages,(newVal) => {
        language.value = newVal;
    })

    const curAccount = computed(()=>{
      return wStore.curAccount
    })
    watch(curAccount, (newVal, oldVal) =>{
        if (newVal) {
            if (oldVal) {
            alert(i18n.global.t('alert.reLogin'))
            }

            wbStore.accountLogin().then((result) => {
            wbStore.setLoginState(true)
            mStore.queryMemberInfo()

            }).catch((reason) => {
            wbStore.setLoginState(false)
            console.error('login failed')
            })
        }
    })

    const copys = (value) => {
        if (value != '-') {
            const input = document.createElement('input')
            input.value = value
            input.id = 'creatDom'
            document.body.appendChild(input)
            input.select()
            document.execCommand('copy')
            document.body.removeChild(input)
            ElMessage({
                type: 'success',
                message: i18n.global.t('alert.copied')
            })
        }
    }


    const getUrl = (url,index) => {
        if(index){
            return require(`../../static/images/advantage${url}.png`)
        }else{
            if(language.value === 'cn'){
                return require(`../../static/images/chart${url}-cn.png`)
            }else{
                return require(`../../static/images/chart${url}-en.png`)
            }
        }
    }

</script>
<style scoped lang="less" src="./index.less">
</style>
